import React, { useEffect } from 'react';
import TickImg from '../Assets/Images/thank-you-tick.svg'
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import useWindowDimensions from '../Hooks/useWindowDimensions';

const InviteThankYou = () => {

	const { id }                   	= useParams();
	const { windowWidth }           = useWindowDimensions();
	
	useEffect(() => { 	
		{windowWidth <= 830 ? document.body.style.backgroundColor = "#007670" : document.body.style.backgroundColor = "#E5E5E5";}
	},[windowWidth]);

	return <>
		<div className="container thank-you">
			<div className="details-container">
				<div className="tick"><img src={TickImg} alt="" /></div>
				<h1>You have successfully invited your team!</h1>
				{/* <p>You'll be notified as your Board Members take the test so you can review aggregate and individual results.</p> */}
				<div className="back-btn">
					<Link to={"/results/" + id}>go back to my results</Link>
				</div>
			</div>
		</div>
		
		{/* <div className="schedule-container">
			Schedule a call with Carey to debrief the results of your test
			and ensure your church is prepared with next steps for future evangelism.
			<div className="cta-btn">
				<a href="https://calendly.com/kurianbk/hear-the-bleat-story" target="_blank">
					<Button>TALK WITH CAREY</Button>
				</a>
			</div>
		</div> */}
	</>;

};

export default InviteThankYou;
