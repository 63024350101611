import React, { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';

import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import hourGlass from '../Assets/Images/hourglass.gif';

import '../App.scss';

const jobTitleValues = [
  {value: "Other", label: "Other"},
  {value: "Administrative Pastor", label: "Administrative Pastor"},
  {value: "Board Member", label: "Board Member"},
  {value: "Campus Pastor", label: "Campus Pastor"},
  {value: "Children's Pastor", label: "Children's Pastor"},
  {value: "College Pastor", label: "College Pastor"},
  {value: "Community Pastor", label: "Community Pastor"},
  {value: "Connections Pastor", label: "Connections Pastor"},
  {value: "Creative Arts Pastor", label: "Creative Arts Pastor"},
  {value: "Discipleship Pastor", label: "Discipleship Pastor"},
  {value: "Executive Pastor", label: "Executive Pastor"},
  {value: "Family Pastor", label: "Family Pastor"},
  {value: "Finance Chair", label: "Finance Chair"},
  {value: "High School Pastor", label: "High School Pastor"},
  {value: "Leadership Development Pastor", label: "Leadership Development Pastor"},
  {value: "Middle School Pastor", label: "Middle School Pastor"},
  {value: "Missions Pastor", label: "Missions Pastor"},
  {value: "Outreach Pastor", label: "Outreach Pastor"},
  {value: "Personal Assistant to Lead Pastor", label: "Personal Assistant to Lead Pastor"},
  {value: "Senior Pastor", label: "Senior Pastor"},
  {value: "Small Group Pastor", label: "Small Group Pastor"},
  {value: "Stewardship Committee", label: "Stewardship Committee"},
  {value: "Stewardship Pastor", label: "Stewardship Pastor"},
  {value: "Student Pastor", label: "Student Pastor"},
  {value: "Teacher Pastor", label: "Teacher Pastor"},
  {value: "Worship Pastor", label: "Worship Pastor"},
  {value: "Administrative Assistant", label: "Administrative Assistant"},  
  {value: "AV Director", label: "AV Director"},
  {value: "Bookkeeper", label: "Bookkeeper"},
  {value: "Chief Financial Officer", label: "Chief Financial Officer"},
  {value: "Children's Ministry Coordinator", label: "Children's Ministry Coordinator"},
  {value: "Communications Coordinator", label: "Communications Coordinator"},
  {value: "Communications Director", label: "Communications Director"},
  {value: "Communications Project Manager", label: "Communications Project Manager"},
  {value: "Content Writer", label: "Content Writer"},
  {value: "Digital Media Analyst", label: "Digital Media Analyst"},
  {value: "Events Coordinator", label: "Events Coordinator"},
  {value: "Facility Manager", label: "Facility Manager"},
  {value: "Graphic Designer", label: "Graphic Designer"},
  {value: "Groups Director", label: "Groups Director"},
  {value: "Guest Services Director", label: "Guest Services Director"},
  {value: "Human Resources Coordinator", label: "Human Resources Coordinator"},
  {value: "IT Director", label: "IT Director"},
  {value: "Mission and Outreach Coordinator", label: "Mission and Outreach Coordinator"},
  {value: "Office Manager", label: "Office Manager"},
  {value: "Online Community Manager", label: "Online Community Manager"},
  {value: "Pastoral Care Minister", label: "Pastoral Care Minister"},
  {value: "Preschool Director", label: "Preschool Director"},
  {value: "Production Coordinator", label: "Production Coordinator"},
  {value: "Social Media Manager", label: "Social Media Manager"},
  {value: "Video Producer", label: "Video Producer"},
  {value: "Volunteer Coordinator", label: "Volunteer Coordinator"},
  {value: "Web Developer", label: "Web Developer"},
  {value: "Worship Leader", label: "Worship Leader"},
];

const Details = (props) => {

  const addCtx                        = useContext(AdditionalQuestionContext);
  let navigate                        = useNavigate();
  const { id }                        = useParams();
  const [searchParams, setSearchParams] = useSearchParams();



  const [isSaving, setIsSaving]       = useState(false);
  const [isLoading, setIsLoading]       = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMember, setIsMember]       = useState(true);

  // const [firstName, setFirstName]     = useState("");
  // const [lastName, setLastName]       = useState("");
  const [fullName, setFullName] = useState("");

  const [email, setEmail]             = useState("");
  const [jobTitle, setJobTitle]       = useState("");
  const [jobTitleMember, setJobTitleMember]       = useState("");
  const [churchName, setChurchName]   = useState("");
  const [schoolName, setSchoolName]   = useState("");
  const [zipCode, setZipCode]         = useState("");
  const [selectedValue, setSelectedValue] = useState();
  // const [firstNameError, setFirstNameError]       = useState(false);
  // const [lastNameError, setLastNameError]         = useState(false);
  const [fullNameError, setFullNameError] = useState(false);
  const [churchWebsite, setChurchWebsite] = useState("")

  const [emailError, setEmailError]               = useState(false);
  const [jobTitleError, setJobTitleError]         = useState(false);
  const [churchNameError, setChurchNameError]     = useState(false);
  const [schoolNameError, setSchoolNameError]     = useState(false);
  const [zipCodeError, setZipCodeError]           = useState(false);
  const [churchWebsiteError, setChurchWebsiteError] = useState(false);

  useEffect(() => {
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
    document.body.style.backgroundColor = "#EEEEEE";
  }, []);

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  // const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  const validUrlRegex = /^(https?:\/\/)?([a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z0-9]{2,})(:\d{1,5})?([/?#]\S*)?$/i;


  useEffect(() => {
    // if (firstName  !== addCtx.formDetails.firstName)  addCtx.addFormDetails((prev) => ({ ...prev, firstName  }));
    // if (lastName   !== addCtx.formDetails.lastName)   addCtx.addFormDetails((prev) => ({ ...prev, lastName   }));
    if (fullName   !== addCtx.formDetails.fullName)   addCtx.addFormDetails((prev) => ({ ...prev, fullName   }));
    if (email      !== addCtx.formDetails.email)      addCtx.addFormDetails((prev) => ({ ...prev, email      }));
    if (jobTitle   !== addCtx.formDetails.jobTitle)   addCtx.addFormDetails((prev) => ({ ...prev, jobTitle   }));
    if (churchName !== addCtx.formDetails.churchName) addCtx.addFormDetails((prev) => ({ ...prev, churchName }));
    if (churchWebsite !== addCtx.formDetails.churchWebsite) addCtx.addFormDetails((prev) => ({ ...prev, churchWebsite }));
    if (zipCode    !== addCtx.formDetails.zipCode)    addCtx.addFormDetails((prev) => ({ ...prev, zipCode    }));
  }, [fullName, email, jobTitle, churchName, churchWebsite, zipCode]);

  useEffect(() => {
    let formDetails = addCtx.formDetails;

    // if (formDetails?.firstName?.length)  setFirstName(formDetails.firstName);
    // if (formDetails?.lastName?.length)   setLastName(formDetails.lastName);
    if (formDetails?.fullName?.length)   setFullName(formDetails.fullName);
    if (formDetails?.email?.length)      setEmail(formDetails.email);
    if (formDetails?.churchName?.length) setChurchName(formDetails.churchName);
    if (formDetails?.zipCode?.length)    setZipCode(formDetails.zipCode);
    if (formDetails?.churchWebsite?.length) setChurchWebsite(formDetails.churchWebsite);

    if (formDetails?.jobTitle?.length) {
      let selected = jobTitleValues?.find(job => job.value === formDetails?.jobTitle);
      // console.log("typeof selected", typeof selected, selected);
      setSelectedValue(selected);
      selected?.value && setJobTitle(selected?.value);
    }
  }, [addCtx.formDetails]);
  
  console.log("addCtx.formDetails", addCtx.formDetails);
  
  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        // setFirstName(data.firstName);
        // setLastName(data.lastName);
        setFullName(data.fullName);
        setEmail(data.email);
        // setJobTitle("none");
        // setJobTitle("");
        // setChurchWebsite(data.churchWebsite);
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  // const updateFirstName = (event) => {
  //   setFirstName(event.target.value);
  //   checkFormValid();
  // }

  // const updateLastName = (event) => {
  //   setLastName(event.target.value);
  //   checkFormValid();
  // }

  useEffect(() => {
    checkFormValid()
  }, [fullName, email, jobTitle, churchName, churchWebsite, zipCode])
  
  const updateFullName = (event) => {
    setFullName(event.target.value);
    checkFormValid();
  }


  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  // const updateJobTitle = (event) => {
  const updateJobTitle = (selectedOption) => {
    // console.log(event.target.value);
    console.log(selectedOption.value);
    setSelectedValue(selectedOption);
    setJobTitle(selectedOption.value);
    checkFormValid();
  }
  
  // const updateSchoolName = (event) => {
  //   setSchoolName(event.target.value);
  //   checkFormValid();
  // }

  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const updateChurchWebsite = (event) => {
    console.log(event.target.value);
    setChurchWebsite(event.target.value);
    checkFormValid();
  } 

  const checkFormValid = () => {
    if(isMember) {
      // console.log(firstName);
      // console.log(jobTitle);
      // console.log(jobTitleMember);
      // if(firstName != "" && lastName != "") {
      //   console.log("We get here");
      //   setIsFormValid(true);
      // } else {
      //   setIsFormValid(false);
      // }
      if(fullName) {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      // if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
      // if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "" && schoolName != "" && zipCode.length > 3 && zipCode.length < 13) {
      if(fullName && email && emailRegex.test(email) && churchName && zipCode.length > 3 && zipCode.length < 13 && churchWebsite.trim() && validUrlRegex.test(churchWebsite.trim())) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }

  const showErrors = () => {
    // if(!firstName) {
    //   setFirstNameError(true);
    //   setTimeout(() => { console.log(firstNameError); }, 2000);
    // }
    // if(!lastName) {
    //   setLastNameError(true);
    // }

    if(!fullName) {
      setFullNameError(true);
      // setTimeout(() => { console.log(fullNameError); }, 2000);
    }

    if(!emailRegex.test(email)) {
      setEmailError(true);
    }
    if(jobTitle == "none" || !jobTitle) {
    // if(jobTitle == "" || !jobTitle) {
      setJobTitleError(true);
    }
    // if(schoolName == "") {
    //   setSchoolNameError(true);
    // }
    if(!churchName) {
      setChurchNameError(true);
    }
    if(zipCode.length < 4) {
      setZipCodeError(true);
    }

    if(!validUrlRegex.test(churchWebsite.trim())){
      setChurchWebsiteError(true);
    }
  }

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }

    let jsonBody        = JSON.stringify({
      details: {
        // "firstName": firstName,
        // "lastName": lastName,
        "fullName": fullName.trim(),
        "email": email.trim(),
        "jobTitle": jobTitle.trim(),
        // "schoolName": schoolName.trim(),
        "churchName": churchName.trim(),
        "zipCode": zipCode.trim(),
        "churchWebsite": churchWebsite.trim(),
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        localStorage.removeItem("assessmentAnswers");
        localStorage.removeItem("additionalAnswers");
        localStorage.removeItem("sections");
        navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    }).catch(err => console.error("Failed to submit details...", err));

  }

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Running");


    // if(isMember && firstName != "" && lastName != "" && jobTitle != "") {
    //   submitToApi();
    // // } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
    // // } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && schoolName != "" && zipCode.length > 3 && zipCode.length < 13) {
    // } else if(!isMember && fullName && emailRegex.test(email) && churchName && zipCode.length > 3 && zipCode.length < 13) {  
    // submitToApi();
    // } else {
    //   showErrors();
    //   setIsSaving(false);
    // }

    if(isMember && fullName && jobTitle && churchWebsite && validUrlRegex.test(churchWebsite)) {
      submitToApi();
    // } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
    } else if(!isMember && fullName && emailRegex.test(email) && churchName && zipCode.length > 3 && zipCode.length < 13 && churchWebsite && validUrlRegex.test(churchWebsite)) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#fff' }} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    // if(field == "firstName") {
    //   if(firstNameError) {
    //     return <div className="error-text">Please confirm your first name.</div>
    //   }
    // }
    // if(field == "lastName") {
    //   if(lastNameError) {
    //     return <div className="error-text">Please confirm your last name.</div>
    //   }
    // }
    if(field == "fullName") {
      if(fullNameError) {
        return <div className="error-text">Please confirm your full name.</div>
      }
    }

    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please provide a job title.</div>
      }
    }
    // if(field == "schoolName") {
    //   if(schoolNameError) {
    //     return <div className="error-text">Please confirm your school name.</div>
    //   }
    // }
    if(field == "churchName") {
      if(churchNameError) {
        return <div className="error-text">Please confirm your church/organization/ministry name.</div>
      }
    }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }

    if(field == "churchWebsite") {
      if(churchWebsiteError) {
        return <div className="error-text">Please confirm your church website.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#3B3A3A")
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#2D8D95";
    }

    // if(field == "firstName") {
    //   setFirstNameError(false);
    // }
    // if(field == "lastName") {
    //   setLastNameError(false);
    // }

    
    if(field == "fullName") {
      setFullNameError(false);
    }

    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    // if(field == "schoolName") {
    //   setSchoolNameError(false);
    // }
    if(field == "churchName") {
      setChurchNameError(false);
    }
    if(field == "zipCode") {
      setZipCodeError(false);
    }

    if(field == "churchWebsite") {
      setChurchWebsiteError(false);
    }


  }

  const prevQuestion = () => {
    // navigate("/assessment-additional/" + id, { replace: true });
    if(isMember) {
      if (searchParams.get("member") || searchParams.get("user")) {
        let memberId = searchParams.get("member") || searchParams.get("user");
        if (searchParams.get("user") && !searchParams.get("member")) {
          // navigate('/assessment-additional/' + id + "/?member=" + memberId + "&" + searchParams, { replace: true });
          navigate("/assessment/" + id + "/review?member=" + memberId + "&" + searchParams, { replace: true });
        } else {
          // navigate('/assessment-additional/' + id + "?" + searchParams, { replace: true });
          navigate("/assessment/" + id + "/review?" + searchParams, { replace: true });
        }
      } 
    } else {
      // navigate('/assessment-additional/' + id + "/?" + searchParams, { replace: true });
      navigate("/assessment/" + id + "/review?" + searchParams, { replace: true });
    }
  };

  if (isLoading) {
		return <div className="container details loading"><CircularProgress  style={{ color: '#007670' }} size={60} /></div>
	}



  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        <h1>
          {/* <img src={hourGlass} width={38} height={38} style={{margin:"-6px 0"}}/> */}
          Your report is in progress
        </h1>
        <div style={{ height: '10px' }}></div>
        <p style={{margin:'16px auto'}}>Please confirm your details so that we can email you a copy.</p>

        <div className="form">

          {/* <div className="form-detail">
            <p className="input-title">First name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Simon" value={firstName} onFocus={(e) => removeHintText("firstName", e)} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Last name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Peter" value={lastName} onFocus={(e) => removeHintText("lastName", e)}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div> */}

          <div className="form-detail">
            <p className="input-title">Full Name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. Simon Peter" value={fullName} onFocus={(e) => removeHintText("fullName", e)}  onChange={(e) => {updateFullName(e)}} fullWidth variant="standard" size="normal"    InputProps={{ disableUnderline: true, style: { width: '100%', ...(fullName && { border: '1px solid #B9B9B9', backgroundColor: '#FFFFFF', borderRadius: '4px', padding: '8px', color: '#000000' })}}} className="form-details-text" />
            {showHintText("fullName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Email<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. speter@rock.church" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true, style: { width: '100%', ...(email && { border: '1px solid #B9B9B9', backgroundColor: '#FFFFFF', borderRadius: '4px', padding: '8px', color: '#000000' })}}}  className="form-details-text" />
            {showHintText("email")}
          </div>

          {
            !isMember ? (
              <div className="form-detail school-name">
                <p className="input-title">Church/Organization/Ministry<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="E.g. Rock Church" value={churchName} onFocus={(e) => removeHintText("churchName", e)} onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true, style: { width: '100%', ...(churchName && { border: '1px solid #B9B9B9', backgroundColor: '#FFFFFF', borderRadius: '4px', padding: '8px', color: '#000000' })}}} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          }

          <div className="form-detail">
            <p className="input-title">Job Title<span className="required-field">*</span></p>

            {/* <TextField hiddenLabel placeholder="Board Member" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}

            {/* <TextField select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option below</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}
             <Select  classNamePrefix="react-select"  className="form-details-text react-select" options={jobTitleValues} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => updateJobTitle(e)} value={selectedValue}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 4,
                  spacing: {
                    ...theme.spacing,
                  },
                  colors: {
                    ...theme.colors,
                    primary25: '#73B76F33',
                    primary50: '#73B76F33',
                    primary: '#2D8D95',
                    neutral0: selectedValue ? '#ffffff' : '#F4F4F4',
                    neutral50: '#B5B5B5',
                    border: !selectedValue ? '#ffffff' : '#B9B9B9', 
                    borderFocus: !selectedValue ? '#ffffff' : '#B9B9B9',
                    borderHover: !selectedValue ? '#ffffff' : '#B9B9B9',
                  }
                })}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border: selectedValue ? '1px solid #B9B9B9' : 'none',
                    boxShadow: state.isFocused ? '0 0 0 1px #B9B9B9' : 'none',
                    '&:hover': {
                      border: selectedValue ? '1px solid #B9B9B9' : 'none',
                    },
                  }),
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: 'none', // Hide the indicator separator
                  }),
                }}
                placeholder={"Select/start typing"}
              />
            {showHintText("jobTitle")}
          </div>

          {/* {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">School name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Rock Church" value={schoolName} onFocus={(e) => removeHintText("schoolName", e)} onChange={(e) => {updateSchoolName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          } */}
          
        
          <div className="form-detail">
            <p className="input-title">Church Website<span className="required-field">*</span></p>

            <TextField hiddenLabel placeholder="E.g. www.rock.church" value={churchWebsite} onFocus={(e) => removeHintText("churchWebsite", e)} onChange={(e) => {updateChurchWebsite(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true, style: { width: '100%', ...(churchWebsite && { border: '1px solid #B9B9B9', backgroundColor: '#FFFFFF', borderRadius: '4px', padding: '8px', color: '#000000' })}}} className="form-details-text" />
            {showHintText("churchWebsite")}
          </div>
          
          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">ZIP Code<span className="required-field">*</span></p>
                <TextField hiddenLabel 
                  type="text" 
                  placeholder="E.g. 11618 " 
                  className="form-details-text" 
                  value={zipCode} 
                  onFocus={(e) => removeHintText("zipCode", e)} 
                  onChange={(e) => {updateZipCode(e)}} 
                  fullWidth variant="standard" size="normal" 
                  InputProps={{ disableUnderline: true, style: { width: '100%', ...(zipCode && { border: '1px solid #B9B9B9', backgroundColor: '#FFFFFF', borderRadius: '4px', padding: '8px', color: '#000000' })}}}
                  onInput = {(e) => {
                    return e.target.value = e.target.value?.toString().slice(0,12)
                  }}
                />
                {showHintText("zipCode")}
              </div>
            ) : null
          }


        </div>
          <div className="details-buttons">
            <div className="back-btn">
              <Button onClick={prevQuestion} style={{color:'#000'}}>
                <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;
                <p className='back-btn-text'>PREV</p>
              </Button>
            </div>

            <div className="cta-btn">
              <Button onClick={handleSubmit} disabled={isSaving || !isFormValid}>
                {showButton()}
              </Button>
            </div>
          </div>

      </div>
      
    </div>
  );
  
}

export default Details;

