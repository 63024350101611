import React, { useState, useEffect } from "react";
import "../App.scss";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useWindowDimensions from "../Hooks/useWindowDimensions";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import Close from "../Assets/Images/close.svg"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

function Tabs(props) {

	const [isLoading, setIsLoading] = React.useState(true);

	const [toggleState, setToggleState] = useState(1);
	const [showMoreDetails, setShowMoreDetails] = useState(false);
	const [questions, setQuestions] = useState([]);
	const [sections, setSections] = useState([]);
	const [toggleMore, setToggleMore] = useState(0);
	const [section1saligned, setSection1sAligned] = useState([]);
	const [section1sout, setSection1sOut] = useState([]);
	const [section2saligned, setSection2sAligned] = useState([]);
	const [section2sout, setSection2sOut] = useState([]);
	const [section3saligned, setSection3sAligned] = useState([]);
	const [section3sout, setSection3sOut] = useState([]);
	const [section4saligned, setSection4sAligned] = useState([]);
	const [section4sout, setSection4sOut] = useState([]);
	const [section5saligned, setSection5sAligned] = useState([]);
	const [section5sout, setSection5sOut] = useState([]);
	const { windowWidth } = useWindowDimensions();
	const [expandedCategories, setExpandedCategories] = useState([]);
	const [sectiontabopenclose, setSectionTabOpenClose] = useState([]);
	const categories = {
		1: 'STRONGLY_DISAGREE',
		2: 'DISAGREE',
		3: 'NEUTRAL',
		4: 'AGREE',
		5: 'STRONGLY_AGREE'
	};

	useEffect(() => {
		updateContent();
		setSectionTabOpenClose(['section1saligned', 'section1sout', 'section2sout', 'section3sout', 'section4sout', 'section5sout', "section2saligned", 'section3saligned', 'section4saligned', 'section5saligned']);
	}, []);



	function getMostCommonAnswer(arr) {

		let count = {};
		let maxCount = 0;
		let mostCommonAns = null;

		for (let i = 0; i < arr?.length; i++) {
			const answer = arr?.[i]?.answer;
			count[answer] = (count[answer] || 0) + 1;

			if (count[answer] > maxCount) {
				maxCount = count[answer];
				mostCommonAns = answer;
			}
		}
		return mostCommonAns;
	}

	const updateContent = () => {

		let members = props.members;
		let questions = props.questions;
		let sections = props.sections;

		let sectionIds = [];
		sections.forEach((section) => {
			sectionIds.push(section.id);
		});

		questions.forEach(question => {

			let answer1 = 0;
			let answer2 = 0;
			let answer3 = 0;
			let answer4 = 0;
			let answer5 = 0;
			let aligned = false;
			let alignedAnswer = 0;
			let memAnswers = [];

			members.forEach(member => {
				let answers = member.answers.find(function (obj) { return obj.question_id == question.id; });
				memAnswers.push({
					name: member.fullName,
					answer: answers.answer
				});
				if (answers.answer == 1) { answer1++; }
				if (answers.answer == 2) { answer2++; }
				if (answers.answer == 3) { answer3++; }
				if (answers.answer == 4) { answer4++; }
				if (answers.answer == 5) { answer5++; }
			});

			let answerArray = [
				{ id: 1, margin: answer1 / members.length },
				{ id: 2, margin: answer2 / members.length },
				{ id: 3, margin: answer3 / members.length },
				{ id: 4, margin: answer4 / members.length },
				{ id: 5, margin: answer5 / members.length }
			];
			answerArray.sort((a, b) => b.margin - a.margin);
			// if(answerArray[0].margin > 0.5) {
			// 	aligned 					= true;
			// 	alignedAnswer 		= answerArray[0].id;
			// }

			let zeroAnswers = 0;
			answerArray.forEach(mem => {
				if (mem.margin == 0) {
					zeroAnswers++;
				}
			});

			if (zeroAnswers > 3) {
				aligned = true;
			} else if (zeroAnswers > 2) {
				let newAnswerArray = answerArray.sort((a, b) => a.id - b.id);
				let memDiff = newAnswerArray.filter(function (obj) { return obj.margin != 0 });
				console.log(memDiff);
				if (memDiff[1].id - memDiff[0].id == 1) {
					aligned = true;
				}
			}

			question.aligned = aligned;
			question.answerArray = answerArray;
			// question.alignedAns	= alignedAnswer;
			question.alignedAns = getMostCommonAnswer(memAnswers);
			question.members = memAnswers;
			question.open = false;

		});

		let section1Aligned = questions.filter(function (obj) { return obj.sectionId == sectionIds[0] && obj.aligned; });
		setSection1sAligned(section1Aligned);
		let section1Out = questions.filter(function (obj) { return obj.sectionId == sectionIds[0] && !obj.aligned; });
		setSection1sOut(section1Out);
		let section2Aligned = questions.filter(function (obj) { return obj.sectionId == sectionIds[1] && obj.aligned; });
		setSection2sAligned(section2Aligned);
		let section2Out = questions.filter(function (obj) { return obj.sectionId == sectionIds[1] && !obj.aligned; });
		setSection2sOut(section2Out);
		let section3Aligned = questions.filter(function (obj) { return obj.sectionId == sectionIds[2] && obj.aligned; });
		setSection3sAligned(section3Aligned);
		let section3Out = questions.filter(function (obj) { return obj.sectionId == sectionIds[2] && !obj.aligned; });
		setSection3sOut(section3Out);
		let section4Aligned = questions.filter(function (obj) { return obj.sectionId == sectionIds[3] && obj.aligned; });
		setSection4sAligned(section4Aligned);
		let section4Out = questions.filter(function (obj) { return obj.sectionId == sectionIds[3] && !obj.aligned; });
		setSection4sOut(section4Out);
		let section5Aligned = questions.filter(function (obj) { return obj.sectionId == sectionIds[4] && obj.aligned; });
		setSection5sAligned(section5Aligned);
		let section5Out = questions.filter(function (obj) { return obj.sectionId == sectionIds[4] && !obj.aligned; });
		setSection5sOut(section5Out);
		// setSection1(section1);

		setQuestions(questions);
		setSections(sections);
		setIsLoading(false);

	}

	const toggleTab = (index) => {
		setToggleState(index);
	};

	const toggleDetails = (id) => {
		if (id == toggleMore) {
			setToggleMore(0);
		} else {
			setToggleMore(id);
		}
	}

	const toggleMoreDisplay = (id) => {
		if (id == toggleMore) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleMoreIcon = (id) => {
		if (id == toggleMore) {
			return <RemoveIcon />
		} else {
			return <AddIcon />
		}
	}

	const showAnswerText = (answer, questionId) => {
		// console.log(answer);
		let question = questions.find(function (obj) { return obj.id == questionId; });
		// console.log(question.answers);
		let answerVal = question.answers.find(function (obj) { return obj.value == answer; });
		if (answerVal && answerVal.desktop_text) {
			<div className="answer false">{answerVal.desktop_text}</div>
		}

		if (answer == 1) {
			return <div className="answer false">STRONGLY DISAGREE</div>
		} else if (answer == 2) {
			return <div className="answer false">DISAGREE</div>
		} else if (answer == 3) {
			return <div className="answer neutral">NEUTRAL</div>
		} else if (answer == 4) {
			return <div className="answer true">AGREE</div>
		} else if (answer == 5) {
			return <div className="answer true">STRONGLY AGREE</div>
		}
	}

	const showIndividualAnswerText = (answer, questionId) => {

		let question = questions.find(function (obj) { return obj.id == questionId; });
		let answerType = "green";
		// console.log(question);

		if (question.answers.length == 2) {
			if (answer == 1) { answerType = "red"; }
			else if (answer == 2) { answerType = "green"; }
		} else if (question.answers.length == 3) {
			if (answer == 1) { answerType = "red"; }
			else if (answer == 2) { answerType = "orange"; }
			else if (answer == 3) { answerType = "green"; }
		} else if (question.answers.length == 4) {
			if (answer == 1) { answerType = "red"; }
			else if (answer == 2) { answerType = "red"; }
			else if (answer == 3) { answerType = "green"; }
			else if (answer == 4) { answerType = "green"; }
		} else if (question.answers.length == 5) {
			if (answer == 1) { answerType = "red"; }
			else if (answer == 2) { answerType = "red"; }
			else if (answer == 3) { answerType = "orange"; }
			else if (answer == 4) { answerType = "green"; }
			else if (answer == 5) { answerType = "green"; }
		}
		let answerVal = question.answers.find(function (obj) { return obj.value == answer; });

		if (answerVal && answerVal.desktop_text) {
			if (answerType == "red") {
				return <div className="answer false">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if (answerType == "orange") {
				return <div className="answer neutral">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			} else if (answerType == "green") {
				return <div className="answer true">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
			}
		}

	}
	const handelsectiontabopenclose = (val) => {
		setSectionTabOpenClose((prevState) => {
			if (prevState.includes(val)) {
				return prevState.filter((item) => item !== val);
			} else {
				return [...prevState, val];
			}
		});
	};

	console.log("section1saligned", section1saligned); console.log("section1sout", section1sout);
	console.log("section2saligned", section2saligned); console.log("section2sout", section2sout);
	console.log("section3saligned", section3saligned); console.log("section3sout", section3sout);
	console.log("section4saligned", section4saligned); console.log("section4sout", section4sout);
	console.log("section5saligned", section5saligned); console.log("section5sout", section5sout);


	if (isLoading) {
		return null;
	}


	const displayQuestionsByCategory = (questions) => {

		const groupedQuestions = questions.reduce((acc, question) => {
			const answerCategory = question.alignedAns;
			if (!acc[answerCategory]) acc[answerCategory] = [];
			acc[answerCategory].push(question);
			return acc;
		}, {});
	
	
		const toggleCategory = (category) => {
			setExpandedCategories((prev) =>
				prev.includes(category)
					? prev.filter((cat) => cat !== category)
					: [...prev, category]
			);
		};
	
		return Object.keys(categories).map((key) => {
			const categoryQuestions = groupedQuestions[key] || [];
			if (categoryQuestions.length === 0) return null;
	
			return (
				<div key={key} className="align-details-mobile">
					<h3 style={{ color: categories[key] === 'NEUTRAL' ? '#FDBF04' : categories[key] === 'DISAGREE' ? '#F17E7E': '#73B76F', padding: '6px 20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #0D5257', fontSize: '13.6px', fontWeight: '400', margin: '0',}}>
						{categories[key]}
						<span	onClick={() => toggleCategory(key)} style={{ cursor: 'pointer' }}>
							{expandedCategories.includes(key) ? (
								<ExpandLessIcon style={{ color: '#1EA74A', fontSize: '38px' }} />
							) : (
								<ExpandMoreIcon style={{ color: '#1EA74A', fontSize: '38px' }} />
							)}
						</span>
					</h3>
					{expandedCategories.includes(key) &&
						categoryQuestions.map((data, index) => (
							<div key={index} className="answered-detail no-wrap">
								<p>{data.question}</p>
							</div>
						))}
				</div>
			);
		});
	};



	return (
		<div className="container-tabs">
			<div className="tab-buttons">
				{
					sections.map((data, index) => {
						let toggleIndex = index + 1;
						return <button key={index} className={toggleState === toggleIndex ? "active" : ""} onClick={() => toggleTab(toggleIndex)}>{data.section}</button>
					}
					)}
			</div>

			<div className="tabs-content">
				<div className={toggleState === 1 ? "content  active-content" : "content"}>
					{
						// section1saligned.length > 0 ?
						<div className="align-details">
							<p className="green">
								Where you are aligned
								<span onClick={() => handelsectiontabopenclose('section1saligned')}>
									{sectiontabopenclose.includes("section1saligned") ? <RemoveIcon /> : <AddIcon />}
								</span>
							</p>
							{sectiontabopenclose.includes("section1saligned") && (
								windowWidth > 600
									? section1saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{data.question}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
									: displayQuestionsByCategory(section1saligned)
							)}
						</div>
						// : null
					}

					{
						// section1sout.length > 0 ?
						<div className="align-details analyze">
							<p className="red">Where you are yet to align  <span onClick={() => handelsectiontabopenclose('section1sout')}>{sectiontabopenclose.includes("section1sout") ? <RemoveIcon /> : <AddIcon />}</span></p>
							{sectiontabopenclose.includes("section1sout") &&
								section1sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<a className="more-toggle">
											{toggleMoreIcon(data.id)}
										</a>
										<p>{data.question}</p>
										<div className="more-details" style={toggleMoreDisplay(data.id)}>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>
												))
											}
										</div>
									</div>
								))
							}
						</div>
						// : null
					}

				</div>

				<div className={toggleState === 2 ? "content  active-content" : "content"}>
					{
						// section2saligned.length > 0 ?
						<div className="align-details">
							<p className="green">
								Where you are aligned
								<span onClick={() => handelsectiontabopenclose('section2saligned')}>
									{sectiontabopenclose.includes("section2saligned") ? <RemoveIcon /> : <AddIcon />}
								</span>
							</p>
							{sectiontabopenclose.includes("section2saligned") && (
								windowWidth > 600
									? section2saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{data.question}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
									: displayQuestionsByCategory(section2saligned)
							)}
						</div>

						// : null
					}

					{
						// section2sout.length > 0 ?
						<div className="align-details analyze">
							<p className="red">Where you are yet to align <span onClick={() => handelsectiontabopenclose('section2sout')}>{sectiontabopenclose.includes("section2sout") ? <RemoveIcon /> : <AddIcon />}</span></p>
							{sectiontabopenclose.includes("section2sout") &&
								section2sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<a className="more-toggle">
											{toggleMoreIcon(data.id)}
										</a>
										<p>{data.question}</p>
										<div className="more-details" style={toggleMoreDisplay(data.id)}>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>
												))
											}
										</div>
									</div>
								))
							}
						</div>
						// : null
					}
				</div>

				<div className={toggleState === 3 ? "content  active-content" : "content"}>
					{sectiontabopenclose.includes("section3saligned") &&
						// section3saligned.length > 0 ?
						<div className="align-details">
							<p className="green">
								Where you are aligned
								<span onClick={() => handelsectiontabopenclose('section3saligned')}>
									{sectiontabopenclose.includes("section3saligned") ? <RemoveIcon /> : <AddIcon />}
								</span>
							</p>
							{sectiontabopenclose.includes("section3saligned") && (
								windowWidth > 600
									? section3saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{data.question}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
									: displayQuestionsByCategory(section3saligned)
							)}
						</div>

						// : null
					}

					{
						// section3sout.length > 0 ?
						<div className="align-details analyze">
							<p className="red">Where you are yet to align <span onClick={() => handelsectiontabopenclose('section3sout')}>{sectiontabopenclose.includes("section3sout") ? <RemoveIcon /> : <AddIcon />}</span></p>
							{sectiontabopenclose.includes("section3sout") &&
								section3sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<a className="more-toggle">
											{toggleMoreIcon(data.id)}
										</a>
										<p>{data.question}</p>
										<div className="more-details" style={toggleMoreDisplay(data.id)}>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>
												))
											}
										</div>
									</div>
								))
							}
						</div>
						// : null
					}
				</div>

				<div className={toggleState === 4 ? "content  active-content" : "content"}>
					{
						// section4saligned.length > 0 ?
						<div className="align-details">
							<p className="green">
								Where you are aligned
								<span onClick={() => handelsectiontabopenclose('section4saligned')}>
									{sectiontabopenclose.includes("section4saligned") ? <RemoveIcon /> : <AddIcon />}
								</span>
							</p>
							{sectiontabopenclose.includes("section4saligned") && (
								windowWidth > 600
									? section4saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{data.question}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
									: displayQuestionsByCategory(section4saligned)
							)}
						</div>

						// : null
					}

					{
						// section4sout.length > 0 ?
						<div className="align-details analyze">
							<p className="red">Where you are yet to align <span onClick={() => handelsectiontabopenclose('section4sout')}>{sectiontabopenclose.includes("section4sout") ? <RemoveIcon /> : <AddIcon />}</span></p>
							{sectiontabopenclose.includes("section4sout") &&
								section4sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<a className="more-toggle">
											{toggleMoreIcon(data.id)}
										</a>
										<p>{data.question}</p>
										<div className="more-details" style={toggleMoreDisplay(data.id)}>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>
												))
											}
										</div>
									</div>
								))
							}
						</div>
						// : null
					}
				</div>

				<div className={toggleState === 5 ? "content  active-content" : "content"}>
					{
						// section5saligned.length > 0 ?
						<div className="align-details">
							<p className="green">
								Where you are aligned
								<span onClick={() => handelsectiontabopenclose('section5saligned')}>
									{sectiontabopenclose.includes("section5saligned") ? <RemoveIcon /> : <AddIcon />}
								</span>
							</p>
							{sectiontabopenclose.includes("section5saligned") && (
								windowWidth > 600
									? section5saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{data.question}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
									: displayQuestionsByCategory(section5saligned)
							)}
						</div>

						// : null
					}

					{
						// section5sout.length > 0 ?
						<div className="align-details analyze">
							<p className="red">Where you are yet to align <span onClick={() => handelsectiontabopenclose('section5sout')}>{sectiontabopenclose.includes("section5sout") ? <RemoveIcon /> : <AddIcon />}</span></p>
							{sectiontabopenclose.includes("section5sout") &&
								section5sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)} style={{ cursor: "pointer" }}>
										<a className="more-toggle">
											{toggleMoreIcon(data.id)}
										</a>
										<p>{data.question}</p>
										<div className="more-details" style={toggleMoreDisplay(data.id)}>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>
												))
											}
										</div>
									</div>
								))
							}
						</div>
						// : null
					}
				</div>
			</div>
		</div>
	);
}

export default Tabs;