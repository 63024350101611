import React, { useEffect } from 'react';

import { Link, useSearchParams } from "react-router-dom";

const Start = props => {

  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("leader");

  const assessmentLink = () => {
    if (searchParams == "") {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  return (
    <div className="container start-page">
      <section className="start-section-1">
        <h1 className='start-heading-desktop' >How aligned is your  leadership team around a plan to grow your financial giving?</h1>
        <h1 className='start-heading-mobile' >How aligned is your <br /> leadership team around a plan to grow financial giving? </h1>

        <p className='start-description-desktop' >Take this 3-minute assessment and unlock expert feedback to chart your course forward.</p>
        {/* <ol className='start-description-mobile' type='1' >
          <li>Take this 3-minute assessment </li>
          <li>Get expert feedback to chart your course forward </li>
        </ol> */}
        <p className='start-description-mobile' type='1' >
          Take this 3-minute assessment and unlock expert feedback to chart your course forward.
        </p>

        <div className="start-btn-container">
          <Link to={assessmentLink()} >
            <button className='start-btn'>Test and see</button>
          </Link>
        </div>
      </section>

      <section className="start-section-2">
        <div className="content"> Looking to get in touch? <span><a href="mailto:melissa@ministryessentials.com">Email us</a></span></div>
      </section>
    </div>
  );
}
export default Start;